
  .center {
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      
  }
  
  .title {
      font-size: 9vw;
      color: orange;
  }
  .subTitle {
      font-size: 3vw;
    }
  .jumbo {
      background: linear-gradient(90deg, rgba(3,17,98,1) 0%, rgba(70,171,211,1) 56%, rgba(231,215,107,1) 100%);
      color: #ccc;
      height: 678px;
      position: relative;
  }
  
  .skyImage{
      width: 100vw;
      max-width:100%;
      opacity: .3;
      z-index: '0';
  }  

  .infoSection {
      max-width:100%;
      margin-top: 50px;
      padding-top: 30px;
      z-index: 1;       
      position: relative;
      background-color: #e4e5e6;
  }
  
  .btnDiv {
      margin-top: 30px;
      margin-left: 10px;
      margin-right: 10px;
      height: 100px;
      text-align:center; 
      
  }

  .btn {
      
      border: 2px solid black;
      background-color: white;
      
      padding: 10px 16px;
      font-size: 16px;
      cursor: pointer;
  }

  .alcohol{
      padding: 0px 30px;
      padding-top: 20px;

  }
  .alcoholHeader{
    color: orange;
    text-align:center;
  }
  .alcoholText{
    color: white;
    font-size: 18px;
    max-width:100%;
    text-align: center;
      
  }

  .learnMore {
      color: white;
      border: 2px solid black;
      background-color: white;
      
      padding: 10px 16px;
      font-size: 16px;
      cursor: pointer;
      background: #ff9800;
      border-color: #ff9800;
      border-radius: 10px;
  }
  
  .learnMore:hover {
  background: white;
  border-radius: 10px;
  color: orange;
  }

  .vision{
      max-width:100%;
      padding: 10px;
      margin-bottom: 50px;
      background-color: white; 
      z-index: 1;
      position: relative;
      
  }
  .visionTitle {
      max-width:100%;
      text-align: center;
      margin-top: 20px; 
      margin-bottom: 30px; 
      font-size: 50px; 
      color: #003f72;

  }
  .visionText {
      max-width:100%;
      
      text-align: center;
      margin-right: 30px;
      margin-left: 30px;
  }
  
  .background {
      
      background-color: #003f72;
      padding-top: 10px;
      padding-bottom: 0px;
      margin: 50px;
      border-radius: 10px;
      box-shadow:  0 0 3px #080808;
      
  }
  .useCaseInfo {
      max-width:100%;
      
      background-color: #003f72;
      color: white;
      margin-right: 50px;
      padding-right: 30px;
      margin-left: 50px;
      padding-left: 30px;
      margin-bottom: 50px;
      padding-bottom: 0px;
      padding-top: 30px;
  }

  @media (max-width: 1440px) {
      .skyImage{
          height: 700px;
          max-width:100%;
         
      }  
      
  }


  @media (max-width: 768px) {
      .title {
          font-size: 14vw;
      }
      .subTitle {
          font-size: 5vw;
      }
      .background {
          margin: 10px;
      }
  }
  
  @media (min-width: 768px) {
      .video {
          margin-left:30vw;
          width: 40vw;
          margin-bottom: 50px;
      }
      .videoDescription {
          font-size: 25px;
          text-align:center;
          vertical-align:middle;
          margin-top: 20px;
          margin-bottom: 20px;
          z-index: 1;
      }
  }
