/* non home pages */

.jumbo {
    background: rgb(2, 51, 99);
    /* background: linear-gradient(90deg, rgba(3,17,98,1) 0%, rgba(70,171,211,1) 56%, rgb(107, 181, 231) 100%); */
    color: #ccc;
    
    padding-top: 100px;
    padding-bottom: 100px;
    position: relative;
    font-size: 50px;
}

.title {
    
    text-align: center;
    margin-top: 20px;
    
    font-size: 50px;
    color: orange;
}
.subTitle {
    text-align: center;
    margin-left: 10vw;
    margin-right: 10vw;
    
    font-size: 20px;
    
    color: white;
}




.contentDiv {  /* for background of content below jumbo */
    background: white;
    
}

.wideDiv {
    background: rgb(234, 240, 247);
    padding: 20px;
}



.textDiv {
    max-width: 1000px;
    margin: auto;
}

.flexContainer {
    display: flex;
    align-items: stretch;
    max-width: 800px;
    /* width: 70%; */
    margin: auto;
}

.boxDiv {
    background-color: #003f72;
    color: white;
    /* flex-grow: 2; */
    text-align: center;
    width: 50%;
    margin:15px;
    padding: 30px;
    box-shadow:  0 0 3px #080808;
    border-radius: 10px;
}


.boxDiv > h1 {
    color: orange;
    text-align:center;
    font-size: 30px;
    font-weight: bold;
  }
.boxDiv > p {
    color: white;
    font-size: 18px;
    max-width:100%;
    text-align: center;
      
  }

  .boxDivWhite {
    background-color: white;
    color: black;
    /* flex-grow: 2; */
    text-align: center;
    width: 33%;
    padding: 5px;
}

.boxDivWhite > h1 {
    color: orange;
    text-align:center;
    font-size: 30px;
    font-weight: bold;
  }

.small {
    display: none;
}
@media(max-width:627px){
    .flexContainer {
        display:block;
        align-items: center;
        margin:auto;
    }
    .boxDiv {
        width:auto ;
        margin-left: 0px;
        margin-right: 0px;
        padding: 10px;
    }


    .boxDivWhite {
        background-color: white;
        color: black;
        /* flex-grow: 2; */
        text-align: center;
        width: auto;
        padding: 5px;
    }
    .large{
        display: none;
    }
    .small {
        display: block;
        }
}
 

    
            

